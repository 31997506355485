<template>
  <div v-if="minimal" class="box site-card-minimal" @click="viewSite">
    <h3 class="is-size-4 has-overflow-ellipsis-right">{{ site.fqdn }}</h3>

    <p>
      <span v-if="userIsSiteOwner">
        <small>
          <b-icon icon="crown" size="is-small" class="has-margin-right-25" />
          <span>Owner</span>
        </small>
      </span>

      <span v-else>
        <small>
          <b-icon icon="user" size="is-small" class="has-margin-right-25" />
          <span>Delegate</span>
        </small>
      </span>

      <small class="has-text-grey">
        | Created
        <span>{{ $moment(site.dateCreated.toDate()).from() }}</span>
      </small>
    </p>
  </div>

  <card v-else class="site-card" @click.native="viewSite">
    <template slot="image">
      <screenshot :filename="site.screenshot" :title="site.fqdn" />
    </template>

    <div slot="body" class="has-text-centered">
      <h3 class="is-size-3 has-overflow-ellipsis-right">{{ site.fqdn }}</h3>

      <p class="has-text-grey has-margin-bottom-50">
        <small>
          Created
          <span>{{ $moment(site.dateCreated.toDate()).from() }}</span>
        </small>
      </p>

      <p v-if="userIsSiteOwner">
        <small>
          <b-icon icon="crown" size="is-small" class="has-margin-right-25" />
          <span>Owner</span>
        </small>
      </p>

      <p v-else>
        <small>
          <b-icon icon="user" size="is-small" class="has-margin-right-25" />
          <span>Delegate</span>
        </small>
      </p>
    </div>
  </card>
</template>

<script>
export default {
  name: "SiteCard",
  props: {
    site: {
      type: Object,
      required: true
    },
    minimal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isResellerUser() {
      return this.$store.getters["user/isResellerUser"]();
    },
    userIsSiteOwner() {
      return (
        this.isResellerUser ||
        (this.site.owners &&
          this.site.owners.includes(this.$store.getters["auth/userId"]()))
      );
    }
  },
  methods: {
    viewSite() {
      this.$router.push({ path: `/sites/${this.site._id}` });
    }
  }
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";
.site-card {
  .card-image {
    padding: 0 2.5rem 0;
    background-color: $dark;
    transition: background-color linear 0.1s;
    picture {
      bottom: -2.5rem;
      background-color: $black;
      box-shadow: 0 0 1rem 0 rgba($black, 0.5);
      transition: bottom linear 0.1s;
    }
  }
  .card-content {
    padding-top: 4rem;
  }
}
.site-card-minimal,
.site-card {
  &:hover {
    cursor: pointer;
    .card-image {
      background-color: $black;
      picture {
        bottom: -2.25rem;
      }
    }
  }
}
</style>
