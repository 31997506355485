<template>
  <div>
    <ribbon class="has-background-white">
      <div class="column is-12 has-text-centered">
        <img src="/assets/images/interface/icons/010-browser.svg" width="64" />
        <h2 class="is-size-3">Manage your sites</h2>
      </div>
    </ribbon>

    <ribbon
      v-if="!$_.isEmpty(sites)"
      class="has-background-white has-border-top"
      padding="bulma-default"
    >
      <div class="column is-12">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <p class="subtitle is-size-5">
                <strong
                  >{{ siteCount }}
                  {{ siteCount > 1 ? "sites" : "site" }} fixed</strong
                >
                , add another&hellip;
              </p>
            </div>
          </div>

          <div v-if="!isResellerUser" class="level-right">
            <p class="level-item">
              <a class="button is-dark is-rounded" @click="addSite"
                >Add new site</a
              >
            </p>
          </div>
        </div>
      </div>
    </ribbon>

    <site-cards @addSite="addSite" />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Sites",
  components: {
    "site-cards": require("./_siteCards").default
  },
  computed: {
    ...mapState({
      sites: state => state.sites.observed
    }),
    user() {
      return this.$store.getters["user/user"]();
    },
    reseller() {
      return this.$store.getters["reseller/reseller"](this.user.resellerId);
    },
    isResellerUser() {
      return this.$store.getters["user/isResellerUser"]();
    },
    userName() {
      return this.$store.getters["user/name"]();
    },
    siteCount() {
      return this.$_.size(this.sites);
    }
  },
  mounted() {
    this.$bus.$emit("scrollToTop");
  },
  methods: {
    addSite() {
      const modal = this.$modal.open({
        component: () => import("@shared/sites/_addSiteModal"),
        parent: this,
        width: 540,
        hasModalCard: true,
        canCancel: [],
        events: {
          success: siteId => {
            modal.close();
            this.$router.push(`/sites/${siteId}`);
          }
        },
        props: {
          parent: this
        }
      });
    }
  }
};
</script>
