<template>
  <ribbon class="has-inset-shadow-top">
    <loading v-if="!observingUserSites" />

    <div
      v-else
      :class="{ 'is-centered': $_.isEmpty(sites) }"
      class="columns is-marginless is-multiline"
    >
      <div v-if="allSites.length" class="column is-12">
        <b-field grouped>
          <b-field expanded>
            <b-input
              v-model="filterComputed"
              :loading="filtering"
              size="is-medium"
              type="text"
              expanded
              placeholder="Filter sites..."
              @input="filtering = true"
            />
            <p class="control">
              <button
                :class="layout !== 'cards' ? 'has-text-grey-lighter' : ''"
                class="button is-medium"
                @click="setLayout('cards')"
              >
                <b-icon icon="th" size="is-small" />
              </button>
            </p>
            <p class="control">
              <button
                :class="layout !== 'list' ? 'has-text-grey-lighter' : ''"
                class="button is-medium"
                @click="setLayout('list')"
              >
                <b-icon icon="th-list" size="is-small" />
              </button>
            </p>
          </b-field>
        </b-field>
      </div>

      <div v-if="$_.isEmpty(sites)" class="column is-12">
        <div class="box has-padding-y-300">
          <no-results
            v-if="isResellerUser"
            :message="`Please bear with us while we add your first site.`"
            icon="spinner"
            title="No sites to show"
          />

          <no-results
            v-else
            :cta="{ label: 'Add site', class: 'is-dark' }"
            icon="grin-alt"
            title="Let's do this!"
            message="Get started by adding your first site."
            @click="$emit('addSite')"
          />
        </div>
      </div>

      <div
        v-for="site in paginatedSites"
        :key="site._id"
        :class="layout === 'cards' ? 'is-4-desktop is-3-fullhd' : ''"
        class="column is-12-mobile is-6-tablet"
      >
        <site-card :site="site" :minimal="layout === 'list'" />
      </div>

      <div v-if="allSites.length > pagination.perPage" class="column is-12">
        <b-pagination
          :total="orderedSites.length"
          :current.sync="pagination.current"
          :order="pagination.order"
          :per-page="pagination.perPage"
        />
      </div>
    </div>
  </ribbon>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
export default {
  name: "SiteCards",
  components: {
    "site-card": require("./_siteCard").default
  },
  data() {
    return {
      filter: "",
      filtering: false,
      layout: null,
      pagination: {
        current: 1,
        perPage: 12,
        order: "",
        size: ""
      }
    };
  },
  computed: {
    ...mapState({
      observingUserSites: state => state.sites.observingUserSites,
      sites: state => state.sites.observed
    }),
    user() {
      return this.$store.getters["user/user"]();
    },
    reseller() {
      return this.$store.getters["reseller/reseller"](this.user.resellerId);
    },
    isResellerUser() {
      return this.$store.getters["user/isResellerUser"]();
    },
    allSites() {
      return this.$_.toArray(this.sites);
    },
    filteredSites() {
      if (!this.filter.length) return this.allSites;
      return this.$_(this.allSites)
        .filter(site => {
          return site.fqdn.includes(this.filter);
        })
        .value();
    },
    orderedSites() {
      return this.$_.orderBy(this.filteredSites, ["fqdn"], ["asc"]);
    },
    paginatedSites() {
      const pageStart = (this.pagination.current - 1) * this.pagination.perPage;
      const pageEnd = pageStart + this.pagination.perPage;
      return this.orderedSites.slice(pageStart, pageEnd);
    },
    filterComputed: {
      get() {
        return this.filter;
      },
      set: _.debounce(function(newValue) {
        this.filter = newValue.toLowerCase();
        this.filtering = false;
        this.pagination.current = 1;
      }, 250)
    }
  },
  created() {
    this.setLayout();
  },
  methods: {
    setLayout(layout) {
      if (!layout) {
        let savedLayout = localStorage.getItem("sites-layout");
        if (!["cards", "list"].includes(savedLayout)) savedLayout = "cards";
        localStorage.setItem("sites-layout", savedLayout);
        this.$set(this, "layout", savedLayout);
      } else {
        if (!["cards", "list"].includes(layout)) layout = "cards";
        localStorage.setItem("sites-layout", layout);
        this.$set(this, "layout", layout);
      }
    }
  }
};
</script>
